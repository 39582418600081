<template>
  <div class="about-us" oncontextmenu="return false" onselectstart="return false">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content" id="pdfDom">
      <div class="table" :class="{'pdfheight': isPrint}">
        <h1 class="title">
          <b v-if="pageId==1">【儿童感觉统合功能评量表（学前版）】
            <br>
            <br>个人报告
          </b>
          <b v-else>【儿童感觉统合功能评量表（学龄版）】
            <br>
            <br>个人报告
          </b>
        </h1>
        <div class="author">
          <p>林巾凯 编制</p>
        </div>
        <!-- 量表介紹 -->
        <p class="title3 tx-l">量表介绍</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th>
              <p
                class="tips tx-idt2"
              >
                本量表由台湾职能治疗－感觉统合专家研发学龄前与学龄儿童皆适用的评量工具，提供家长、照顾者、教师与治疗师使用，可早期筛查发现儿童在感觉统合方面的障碍，以利儿童获得早期疗育、寻求医疗与复健或教育的协助与介入。并帮助治疗师判断障碍对功能性表现的影响程度，作为介入处理治疗的依据。</p>
            </th>
          </tr>
        </table>
        <div class="head-title">
          <p class="title3 tx-l">基本资料</p>
          <p class="title3 tx-l">个案编号：{{baseInfo.sn}}</p>
        </div>
              <table border="1" cellspacing="1" cellpadding="0">
            <tr>
              <th>儿童姓名</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.child_name" />
                </div>
              </td>
              <th>儿童性别</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.detail.sex" />
                </div>
              </td>
            </tr>
            <tr>
              <th>年级</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.detail.grade" />
                </div>
              </td>
            
              <th>班级</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.detail.class" />
                </div>
              </td>
            </tr>
            <tr>
              <th>出生日期</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.birthday" />
                </div>
              </td>
             <th>实足年龄</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.age" />
                </div>
              </td>

              </td>
            </tr>
            <tr>
              
                <th>换算月龄</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.month_age" />
                </div>
              </td>
              <th>评量日期</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.create_time" />
                </div>
              </td>
            </tr>
            <tr>
          
              <th>填答人员</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.operator"
                  />
                </div>
              </td>
              <th>与儿童关系</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.relation"
                  />
                </div>
              </td>
            </tr>
            <tr>
                <th>联系电话</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.mobile"
                  />
                </div>
              </td>
                  <th>障碍问题</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.disable"
                  />
                </div>
              </td>
           
              
            </tr>
            <tr>
                 <th>资源班补救教学</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.is_school"
                  />
                </div>
              </td>
              <th>在校成绩-国语</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.chinese"
                  />
                </div>
              </td>
            
            </tr>
            <tr>
              <th>在校成绩-数学</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.detail.math" />
                </div>
              </td>
              <th>智力测验</th>
              <td>
                <div class="input">
                  <input type="text" readonly v-model="baseInfo.detail.iq" />
                </div>
              </td>
            </tr>
       
          </table>
          <!--  -->
          <p class="title3 tx-l">家庭资料</p>
          <table border="1" cellspacing="1" cellpadding="0">
            <tr>
              <th width="165px">主要照顾者</th>
              <td colspan="3">
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.care_man"
                  />
                </div>
              </td>
              <!--              <th>家庭年收入</th>-->
              <!--              <td>-->
              <!--                <div class="input">-->
              <!--                  <input type="text" readonly v-model="baseInfo.detail.income"/>-->
              <!--                </div>-->
              <!--              </td>-->
            </tr>
            <tr>
              <th>父亲年龄</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.father_age"
                  />
                </div>
              </td>
              <th>母亲年龄</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.mother_age"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>父亲教育程度</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.father_edu"
                  />
                </div>
              </td>
              <th>母亲教育程度</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.mother_edu"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>父亲职业类别</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.father_job"
                  />
                </div>
              </td>
              <th>母亲职业类别</th>
              <td>
                <div class="input">
                  <input
                    type="text"
                    readonly
                    v-model="baseInfo.detail.mother_job"
                  />
                </div>
              </td>
            </tr>
          </table>
        <p class="title3 tx-l">本量表结果</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th>向度</th>
            <th>百分等级</th>
            <th>结果</th>
          </tr>
          <tr>
            <td width="17%" class="tx-c">感觉统合功能总分</td>
            <td width="33%">
              <p class="tx-c">{{total.percent}}</p>
            </td>
            <td v-if="total.percent>=95" class="tx-c">属障碍组的儿童，宜尽速鉴定、转介、治疗。</td>
            <td v-else-if="total.percent>=85" class="tx-c">属疑似障碍的儿童。</td>
            <td v-else-if="total.percent>=73" class="tx-c">属整合功能差的儿童。</td>
            <td v-else class="tx-c">属正常范围。</td>
          </tr>
        </table>
        <!-- 百分等级分数说明 -->
        <p class="title3 tx-l">百分等级分数说明</p>
        <table border="1" cellspacing="1" cellpadding="0">
          <tr>
            <th colspan="4">
              <p class="tips">1. 百分等级在72以下者属正常范围。
                <br>2. 百分等级在73以上者属整合功能差的儿童。
                <br>3. 百分等级在85以上者属疑似障碍的儿童。
                <br>4. 百分等级在95以上者属障碍组的儿童，宜尽速鉴定、转介、治疗。
              </p>
            </th>
          </tr>
        </table>
        <!-- 向度分析 -->
      </div>
      <div class="table" :class="{'pdfheight': isPrint}">
        <p class="title3 tx-l">向度分析</p>
        <table border="1" cellspacing="1" cellpadding="0">
           <tr>
              <th width="200">向度</th>
              <th>原始分数</th>
              <th>平均值</th>
              <th>百分位数</th>
              <th>结果</th>
            </tr>
            <tr v-for="(item, index) in cateScore" :key="index">
              <td class="tx-c gray">{{ item.cate_name }}</td>
              <td class="tx-c">{{ item.score }}</td>
              <td class="tx-c">{{ item.average }}</td>
              <td class="tx-c">{{ item.percent }}</td>
              <td class="tx-c">{{ item.conclusion }}</td>
            </tr>
        </table>
        <!-- 剖面图 -->
        <p class="title3 tx-l">儿童感觉综合评量表侧面图</p>
        <div class="charts" style="height: 600px;" id="charts1"></div>
      </div>
      <div class="table" :class="{'pdfheight': isPrint}">
        <!-- 综合分析 -->
        <p class="title3 tx-l">综合分析</p>
        <p class="title3 tx-l" v-if="weak.uncertain.length">◆ 疑似障碍项目</p>
        <div class="tips" v-if="weak.uncertain.length">
          儿童在
          <span v-for="(list, index) in weak.uncertain" :key="index">
            <b class="">{{list.cate_name}}</b>
            <span v-if="index!=weak.uncertain.length-1">、</span>
          </span>
          达到百分等级85以上，属于疑似障碍。建议一至三个月之后再次进行评估，以确认其状况是否属于障碍，或是针对该向度进行感觉统合游戏训练以增进儿童感觉统合能力。
        </div>
        <p class="title3 tx-l" v-if="weak.certain.length">◆ 障碍项目</p>
        <div class="tips" v-if="weak.certain.length">
          儿童在
          <span v-for="(list, index) in weak.certain" :key="index">
            <b class="">{{list.cate_name}}</b>
            <span v-if="index!=weak.certain.length-1">、</span>
          </span>
          达到百分等级95以上，可能是感觉统合障碍或受到其他因素影响造成障碍，宜尽速鉴定、转介与治疗。
        </div>
        <!-- ◆	解释与建议 -->
        <p class="title3 tx-l">◆ 解释与建议</p>
        <div class="tips">
          <div v-for="(item, index) in weak.suggest" :key="index" v-if="index<5">
            <p class="tx-idt2" style="line-height: 1.6;">{{item.desc}}</p>
            <br>
          </div>
          <!--<p class="tx-idt2" v-for="(item, index) in weak.uncertain" :key="index">{{item.desc}}</p>-->
          <p class="tx-idt2" style="line-height: 1.6;" v-if="weak.suggest.length<5">
            根据本量表可以判断评量结果得低分的儿童其感觉统合功能佳，但是儿童在本量得高分的儿童，则不应武断地说其感觉统合功能有障碍。使用本量表之评量者要熟悉感觉统合理论，才做出合理适当的解释，甚至推论障碍之原因。在感觉统合功能得分达百分等级85以上，代表疑似感觉统合障碍，教师、家长宜转介个案至职能治疗师做进一步的鉴定，以确定是否有感觉统合障碍，是否需要接受感觉统合治疗。在感觉统合功能得分达到百分等级95以上，可能是感觉统合障碍或受到其他因素影响造成障碍，必须尽速转介给职能治疗师做更精密的感觉统合评量以确定障碍之原因，并拟订有关感觉统合的个别化治疗计划。
          </p>
        </div>
      </div>
      <div class="table" :class="{'pdfheight': isPrint}">
        <div class="tips" v-if="weak.suggest.length>=5">
          <div v-for="(item, index) in weak.suggest" :key="index" v-if="index>=5">
            <p class="tx-idt2" style="line-height: 1.6;">{{item.desc}}</p>
            <br>
          </div>
          <!--<p class="tx-idt2" v-for="(item, index) in weak.uncertain" :key="index">{{item.desc}}</p>-->
          <p  class="tx-idt2" style="line-height: 1.6;" v-if="weak.suggest.length>=5">
            根据本量表可以判断评量结果得低分的儿童其感觉统合功能佳，但是儿童在本量得高分的儿童，则不应武断地说其感觉统合功能有障碍。使用本量表之评量者要熟悉感觉统合理论，才做出合理适当的解释，甚至推论障碍之原因。在感觉统合功能得分达百分等级85以上，代表疑似感觉统合障碍，教师、家长宜转介个案至职能治疗师做进一步的鉴定，以确定是否有感觉统合障碍，是否需要接受感觉统合治疗。在感觉统合功能得分达到百分等级95以上，可能是感觉统合障碍或受到其他因素影响造成障碍，必须尽速转介给职能治疗师做更精密的感觉统合评量以确定障碍之原因，并拟订有关感觉统合的个别化治疗计划。</p>
        </div>
       <!--<p class="title3 tx-l">◆ 备注</p>
        <div class="tips">
          <p class="">如果您对本测验结果或解释有任何疑问，或是想进一步了解的地方，请与施测人员共同澄清讨论。</p>
        </div>
        <br>
        -->
        <p class="title3 tx-l">◆ 补充说明</p>
        <div class="tips">
          <p class="">{{baseInfo.remark}}</p>
        </div>
        
      </div>
    </div>
    <button class="print" v-on:click="printPdf">打印</button>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav";
import FooterNav from "@/components/FooterNav";
import echarts from "echarts";
export default {
  name: "aboutus",
  data() {
    return {
      baseInfo: {},
      cateScore: [],
      high: [],
      highest: [],
      weak: {},
      total: "",
      isPrint: false,
      pageId: "",
    };
  },
  components: {
    HeaderNav,
    FooterNav,
    echarts,
  },
  methods: {
    getData() {
      let params = {
        exam_id: this.$route.query.eid,
      };
      this.$post("report/getReport", params).then((res) => {
        if (res.code === 1) {
          this.baseInfo = res.data.base_info;
          this.cateScore = res.data.cate_score;
          let index = this.cateScore.length - 1;
          this.total = this.cateScore[index];
          this.weak = res.data.weak;
          this.$nextTick(() => {
            this.drawLine();
          });
          this.$nextTick(() => {
            if (this.$route.query.print) {
              this.printPdf();
            }
          });
        } else {
          this.$layer.msg(res.msg);
        }
      });
    },
    drawLine() {
      let cateScore = this.cateScore;
      let myChart = echarts.init(document.getElementById("charts1"));
      let xdata = [];
      let ydata = [];
      for (let i = 0; i < cateScore.length; i++) {
        xdata.push(cateScore[i].cate_name);
        ydata.push(cateScore[i].percent);
      }
      myChart.setOption({
        grid: {
          top: "2%",
          left: "4%",
          right: "4%",
          bottom: "8%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xdata,
          axisLabel: {
            textStyle: {
              fontSize: "16",
              color: "#3e333d",
            },
            interval: 0,
            formatter: function (value) {
              //x轴的文字改为竖版显示
              var str = value.split("");
              return str.join("\n");
            },
          },
        },
        yAxis: [
          {
            name: "百\n分\n等\n级",
            type: "value",
            max: 99,
            min: 0,
            splitNumber: 20,
            nameLocation: "middle",
            nameRotate: 0,
            nameGap: 30,
            nameTextStyle: {
              fontSize: 18,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#664c69"],
                width: 1,
                type: "dotted",
              },
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: [
                  "#fff",
                  "#fff",
                  "#fff",
                  "#fff",
                  "#fff",
                  "#fff",
                  "#fff",
                  "#fff",
                  "#fff",
                  "#fff",
                  "#fff",
                  "#fff",
                  "#fff",
                  "#fff",
                  "#fff",
                  "#fff",
                  "#fff",
                  "#b8aab7",
                  "#b8aab7",
                  "#a28aa0",
                ],
              },
            },
          },
          {
            name: "百\n分\n等\n级",
            type: "value",
            max: 99,
            min: 0,
            splitNumber: 20,
            nameLocation: "middle",
            nameRotate: 0,
            nameGap: 30,
            nameTextStyle: {
              fontSize: 18,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            data: ydata,
            textStyle: {
              fontSize: 16,
            },
            type: "line",
            lineStyle: {
              color: "#3e333d",
            },
          },
        ],
      });
    },
    printPdf() {
      this.isPrint = true;
      setTimeout(() => {
        this.getPdf("儿童感觉统合功能评量表", () => {
          this.isPrint = false;
        });
      }, 1500);
    },
  },
  mounted() {},
  created() {
    this.getData();
    this.pageId = this.$route.query.id;
  },
};
</script>

<style lang="less" scoped>
@import url("../../assets/css/page/result");
</style>
